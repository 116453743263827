<template>
	<header :class="{ 'banner--transparent': !isLoggedIn }" class="banner">
		<div class="constrained">
			<div class="flex">
				<nuxt-link :to="basePath" class="banner__logo flex items-end">
          <div class="flex items-center">
            <img
              v-if="isFetching"
              class="mr-2"
              height="48"
              src="/img/gms-myprofile-blue-01.svg"
              width="48"
            />
            <avatar
              v-else-if="accountName"
              :size="48"
              :username="accountName"
              class="mr-2 flex-shrink-0"
            />
            <div class="text-grey-darker">
              <div class="text-xs leading-tight">{{ $t('FOREST_OWNER_ACCOUNT') }}</div>
              <div class="text-xl leading-none">
                {{ isFetching ? $t('LOADING') : accountName }}
              </div>
            </div>
          </div>
          <div class="ml-2 mb-1">
            <GMBadge v-if="isShareholder" icon="badge-check">{{ $t('SHAREHOLDER') }}</GMBadge>
            <GMBadge color="blue" v-else-if="!isShareholder">{{ $t('NOT_SHAREHOLDER') }}</GMBadge>
          </div>
				</nuxt-link>
				<menu-forest-owner/>
			</div>
		</div>
	</header>
</template>
<script>
import {GMAvatar as Avatar, GMBadge} from '@gm/components'
import {mapState} from "pinia";
import MenuForestOwner from '../MenuForestOwner.vue'
import {useAccountStore} from "~/stores/account";
import {useAccountsStore} from "~/stores/accounts";
import {useUserStore} from "~/stores/user";
import {useDocumentsStore} from "~/stores/documents";

export default {
	components: {
		Avatar,
    GMBadge,
		MenuForestOwner,
	},
  setup() {
    const documentsStore = useDocumentsStore()
    return {
      documentsStore
    }
  },
	computed: {
    ...mapState(useAccountStore, {
      accountIsFetching: "isFetching",
      accountName: 'accountName',
      basePath: 'getBasePath',
      teamName: 'accountTeamName',
      account: 'activeAccount',
    }),
    ...mapState(useAccountsStore, {accountsIsFetching: "isFetching"}),
    isFetching() {
      return this.accountIsFetching || this.accountsIsFetching
    },
    isShareholder() {
      const accountStore = useAccountStore()
      return accountStore.hasRole('shareholder')
    },
		...mapState(useUserStore, ['isLoggedIn']),
	},
	watch: {
		account: {
			handler() {
				this.documentsStore.fetch()
			},
			immediate: true
		}
	},
}
</script>
