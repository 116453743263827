<template>
	<nuxt-link :to="basePath" class="banner__logo">
		<img
			v-if="isFetching"
			src="/img/gms-myprofile-blue-01.svg"
			width="48"
			height="48"
			class="mr-2"
		/>
		<div v-else class="relative mr-2 flex-shrink-0">
			<avatar v-if="accountName" :username="accountName" :size="48" />
			<avatar
				v-if="teamName != accountName"
				:username="teamName"
				:size="20"
				class="absolute bottom-0 self-center ml-8 mt-12"
			/>
		</div>
		<div class="text-grey-darker">
			<div class="text-xs leading-tight">{{ $t('CONTRACTOR') }}</div>
			<div class="text-xl leading-none">
				{{ isFetching ? $t('LOADING') : accountName }}
			</div>
			<div v-if="!isFetching" class="text-xs">
				<span v-if="teamName != accountName">
					{{ teamName }}
				</span>
				<span
					v-else
					class="absolute self-center p-1 rounded-full text-xs bg-blue-darker text-white"
				>
					{{ $t('ADMIN') }}
				</span>
			</div>
		</div>
	</nuxt-link>
</template>
<script>
import {GMAvatar as Avatar} from '@gm/components'
import {mapState} from "pinia";
import {useAccountStore} from "~/stores/account";
import {useAccountsStore} from "~/stores/accounts";

export default {
	components: { Avatar },
	computed: {
    ...mapState(useAccountStore, {
      accountIsFetching: "isFetching",
      accountName: 'accountName',
      basePath: 'getBasePath',
      teamName: 'accountTeamName',
    }),
    ...mapState(useAccountsStore, {accountsIsFetching: "isFetching"}),
		isFetching() {
      return this.accountIsFetching || this.accountsIsFetching
		},
	},
}
</script>
