<template>
	<div class="relative">
		<span v-if="accounts.length === 1" class="mx-2">
			<slot />
		</span>
		<button
			v-else
			:aria-expanded="`${show}`"
			:aria-controls="`accountSwitchButton_${_uid}_content`"
			:class="buttonClass"
			@click="show = !show"
		>
			<span class="leading-6">
				<slot />
			</span>
			<svg-icon
class="transition inline fill-current text-grey"
				:style="show ? 'transform: rotateX(-180deg);' : ''"
				width="10"
				height="10" name="chevron-down" />
		</button>

		<transition name="transition--fade">
			<div
				v-show="show"
				:id="`accountSwitchButton_${_uid}_content`"
				:aria-hidden="`${!show}`"
				class="absolute z-40 min-w-full w-48 bg-cyan-lighter border-2 border-blue-lighter shadow"
			>
				<div v-for="(group, index) in groups" :key="group.name">
					<div
						class="pt-3 px-3 pb-1 text-xs text-grey"
						:class="{ 'border-t-2 border-blue-lightest pt-6': index > 0 }"
					>
						{{ group.name }}
						<span class="text-xs">({{ $t(getRole(group.role).name) }})</span>
					</div>
					<nuxt-link
						v-for="account in group.accounts"
						:key="account.id"
						:to="account.path"
						class="group transition flex border-t-2 border-blue-lightest p-3 text-black no-underline hover:bg-white focus:bg-white"
						active-class="bg-white"
						@click.native="show = false"
					>
						<svg-icon
width="16"
							height="16"
							class="mr-2 fill-current text-transparent"
							:class="{
								'text-blue': account.id == accountStore.activeAccount,
								'group-hover:text-blue-lightest': account.id != accountStore.activeAccount,
							}" name="check" />
						{{ account.name }}
					</nuxt-link>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
import { CUSTOMER_ROLES } from '~/store/constants/customer'
import {useAccountsStore} from "~/stores/accounts";
import {useAccountStore} from "~/stores/account";

export default {
	props: {
		buttonClass: {
			type: String,
			default: '',
		},
	},
  setup() {
    const accountStore = useAccountStore();
    const accountsStore = useAccountsStore();
    return {
      accountStore,
      accountsStore,
    }
  },
	data() {
		return {
			show: false,
		}
	},
	computed: {
		accounts() {
			return this.accountsStore.items
		},
		groups() {
			const groups = []
			const groupIds = []

			this.accounts.forEach((account) => {
				const existingIndex = groupIds.findIndex(
					(id) => id === (account.EntrepreneurId || account.AccountId)
				)
				const id = account.AccountId
				const name = this.getName(account)
				const pathPrefix =
					(CUSTOMER_ROLES.find((role) => role.id === account.Role) &&
						CUSTOMER_ROLES.find((role) => role.id === account.Role).path) ||
					''
				const path = `/${pathPrefix}/${id}`
				if (existingIndex > -1) {
					groups[existingIndex].accounts.push({ id, name, path })
				} else {
					groupIds.push(account.EntrepreneurId || account.AccountId)
					groups.push({
						name: account.EntrepreneurName || account.AccountName,
						role: account.Role,
						accounts: [{ id, name, path }],
					})
				}
			})

			return groups
		},
		roles() {
      const accountsStore = useAccountsStore()
      return accountsStore.roles
		},
	},
	mounted() {
		document.addEventListener('click', this.eventHide)
	},
	beforeDestroy() {
		document.removeEventListener('click', this.eventHide)
	},
	methods: {
		getName(account) {
			if (account.AccessLevel === 1) {
				return 'ADMIN'
			}

			return account.AccountName || 'NN'
		},
		getRole(roleId) {
			return (
				(this.roles &&
					Array.isArray(this.roles) &&
					this.roles.find((role) => role.id === roleId)) || {
					name: 'UNKNOWN',
				}
			)
		},
		eventHide(event) {
			if (!this.$el.contains(event.target)) {
				this.show = false
			}
		},
	},
}
</script>
